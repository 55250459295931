<template>
    <modal-lateral ref="modalProductos" titulo="Listado de productos">
        <div class="row m-3">
            <div class="col px-2 my-auto">
                <el-input v-model="buscar" clearable placeholder="Buscar producto a añadir" class="br-20" @input="buscar_productos" />
            </div>
        </div>
        <div class="col px-0 alto">
            <div class="p-3 f-15 overflow-auto custom-scroll" style="max-height: calc(100% - 55px);">
                <div class="row mx-0 justify-center">
                    <card-busqueda-producto
                    v-for="(prod, idx) in listado_productos" :key="`prodB-${idx}`"
                    :producto="prod"
                    @agregar="agregar_producto"
                    />
                </div>
                <div class="d-middle-center px-3 text-center" style="height:40vh">
                    <div v-if="buscar === ''" class="">
                        <p class="f-600 f-17">¿Qué producto estás buscando?</p>
                        <img height="115" width="115" src="/img/modales/magnifying_smile.svg" alt="" />
                    </div>
                    <div v-if="listado_productos.length === 0 && buscar!== '' " class="">
                        <p class="f-600 f-17">No hemos encontrador coincidencias con tu búsqueda</p>
                        <img height="115" width="115" src="/img/modales/magnifying_sad.svg" alt="" />
                    </div>
                </div>
            </div>
        </div>
    </modal-lateral>
</template>

<script>
import Pedidos from '~/services/pedidos/pedidos-tienda'
import {mapGetters} from 'vuex'
export default {
    components:{
        cardBusquedaProducto: () => import('./card-producto-busqueda'),
    },
    data(){
        return{
            verDetalle: 0,
            listado_productos:[],
            buscar:''
        }
    },
    computed:{
        ...mapGetters({
            id_pedido: 'pedidos/pedidos/id_pedido',
        })
    },
    methods: {
        toggle(){
            if(this.id_pedido === null){
                this.notificacion('Alerta','Por favor selecciona un pedido','warning')
                return
            }
            this.$refs.modalProductos.toggle()
        },
        async buscar_productos(query){
            if(query.length > 2){
                const busqueda = async() => {
                    console.log(query);
                    let params = {
                        id_pedido:this.id_pedido,
                        query
                    }
                    const {data} = await Pedidos.buscar_productos(params)
                    this.listado_productos = data.productos
                }
                await this.delay(busqueda)
            }else if(query.length === 0){
                this.listado_productos = []
            }
        },
        remover_de_lista(id_producto){
            let idx = this.listado_productos.findIndex(p=>p.id === id_producto)
            this.listado_productos.splice(idx, 1);
        },
        async agregar_producto(id_producto){
            try {

                let model = {
                    id_pedido:this.id_pedido,
                    id_producto,
                }

                if(model.id_pedido === null){
                    this.notificacion('Alerta','Por favor selecciona un pedido','warning')
                    return
                }
                // console.log(model);
                // return
                const {data} = await Pedidos.agregar_producto(model)
                this.remover_de_lista(id_producto)
                this.$nextTick()
                this.$store.commit('pedidos/pedidos/push_producto',data.registro)
                this.$store.dispatch('pedidos/pedidos/pedidos_resumenes', this.id_pedido)
                this.$emit('update')

            } catch (e){
                this.error_catch(e)
            }

        }
    }
}
</script>

<style lang="scss" scoped>
.alto{
    height: calc(100vh - 64px);
}
</style>
